import React from 'react';
import ReactDOM from 'react-dom/client';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <SwaggerUI url={process.env.REACT_APP_SCHEMA_URL} />
  </React.StrictMode>
);
